import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page", staticStyle: { height: "100%" } },
    [
      _c(
        VTabs,
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(VTab, { key: "0" }, [_vm._v("HOME")]),
          _c(VTab, { key: "1", attrs: { disabled: !_vm.isServer } }, [
            _vm._v("AI"),
          ]),
        ],
        1
      ),
      _c(
        VTabsItems,
        {
          staticStyle: { height: "100%" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            VTabItem,
            [
              _vm.isServer
                ? _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(VCardTitle, [_vm._v("Context Mapping Model")]),
                          _c(
                            VCardText,
                            {
                              staticStyle: {
                                width: "100%",
                                "white-space": "nowrap",
                                "overflow-x": "scroll",
                              },
                            },
                            [
                              _c(
                                VRow,
                                {
                                  staticStyle: {
                                    height: "100%",
                                    margin: "2px",
                                    width: "max-content",
                                  },
                                },
                                [
                                  _vm._l(_vm.cmModelLists, function (id) {
                                    return _c(
                                      "div",
                                      { key: id },
                                      [
                                        _c("jump-to-model-lists-card", {
                                          attrs: { id: id, path: "cm" },
                                          on: {
                                            deleteDefinition:
                                              _vm.openDeleteDialog,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                      style:
                                        _vm.cmModelLists.length == 0
                                          ? "height: 150px"
                                          : "",
                                      attrs: { flat: "" },
                                    },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          "align-items":
                                                            "center",
                                                          width: "100%",
                                                          height: "100%",
                                                        },
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openStorageDialog(
                                                              "cm"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1876023974
                                          ),
                                        },
                                        [_c("span", [_vm._v("add Model")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VDivider),
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(VCardTitle, [_vm._v("Customer Journey Map")]),
                          _c(
                            VCardText,
                            {
                              staticStyle: {
                                width: "100%",
                                "white-space": "nowrap",
                                "overflow-x": "scroll",
                              },
                            },
                            [
                              _c(
                                VRow,
                                {
                                  staticStyle: {
                                    height: "100%",
                                    margin: "2px",
                                    width: "max-content",
                                  },
                                },
                                [
                                  _vm._l(_vm.cjmModelLists, function (id) {
                                    return _c(
                                      "div",
                                      { key: id },
                                      [
                                        _c("jump-to-model-lists-card", {
                                          attrs: { id: id, path: "cjm" },
                                          on: {
                                            deleteDefinition:
                                              _vm.openDeleteDialog,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                      style:
                                        _vm.cjmModelLists.length == 0
                                          ? "height: 150px"
                                          : "",
                                      attrs: { flat: "" },
                                    },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          "align-items":
                                                            "center",
                                                          width: "100%",
                                                          height: "100%",
                                                        },
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openStorageDialog(
                                                              "cjm"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3005965260
                                          ),
                                        },
                                        [_c("span", [_vm._v("add Model")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VDivider),
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(VCardTitle, [_vm._v("Business Model")]),
                          _c(
                            VCardText,
                            {
                              staticStyle: {
                                width: "100%",
                                "white-space": "nowrap",
                                "overflow-x": "scroll",
                              },
                            },
                            [
                              _c(
                                VRow,
                                {
                                  staticStyle: {
                                    height: "100%",
                                    margin: "2px",
                                    width: "max-content",
                                  },
                                },
                                [
                                  _vm._l(_vm.bmModelLists, function (id) {
                                    return _c(
                                      "div",
                                      { key: id },
                                      [
                                        _c("jump-to-model-lists-card", {
                                          attrs: {
                                            id: id,
                                            path: "business-model-canvas",
                                          },
                                          on: {
                                            deleteDefinition:
                                              _vm.openDeleteDialog,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                      style:
                                        _vm.bmModelLists.length == 0
                                          ? "height: 150px"
                                          : "",
                                      attrs: { flat: "" },
                                    },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          "align-items":
                                                            "center",
                                                          width: "100%",
                                                          height: "100%",
                                                        },
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openStorageDialog(
                                                              "bm"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            924991143
                                          ),
                                        },
                                        [_c("span", [_vm._v("add Model")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VDivider),
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(VCardTitle, [_vm._v("UserStory Map")]),
                          _c(
                            VCardText,
                            {
                              staticStyle: {
                                width: "100%",
                                "white-space": "nowrap",
                                "overflow-x": "scroll",
                              },
                            },
                            [
                              _c(
                                VRow,
                                {
                                  staticStyle: {
                                    height: "100%",
                                    margin: "2px",
                                    width: "max-content",
                                  },
                                },
                                [
                                  _vm._l(_vm.usmModelLists, function (id) {
                                    return _c(
                                      "div",
                                      { key: id },
                                      [
                                        _c("jump-to-model-lists-card", {
                                          attrs: {
                                            id: id,
                                            path: "userStoryMap",
                                          },
                                          on: {
                                            deleteDefinition:
                                              _vm.openDeleteDialog,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                      style:
                                        _vm.usmModelLists.length == 0
                                          ? "height: 150px"
                                          : "",
                                      attrs: { flat: "" },
                                    },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          "align-items":
                                                            "center",
                                                          width: "100%",
                                                          height: "100%",
                                                        },
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openStorageDialog(
                                                              "userStoryMap"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2284893638
                                          ),
                                        },
                                        [_c("span", [_vm._v("add Model")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VDivider),
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(VCardTitle, [_vm._v("Event Storming Model")]),
                          _c(
                            VCardText,
                            {
                              staticStyle: {
                                width: "100%",
                                "white-space": "nowrap",
                                "overflow-x": "scroll",
                              },
                            },
                            [
                              _c(
                                VRow,
                                {
                                  staticStyle: {
                                    height: "100%",
                                    margin: "2px",
                                    width: "max-content",
                                  },
                                },
                                [
                                  _vm._l(_vm.esModelLists, function (id) {
                                    return _c(
                                      "div",
                                      { key: id },
                                      [
                                        _c("jump-to-model-lists-card", {
                                          attrs: { id: id, path: "storming" },
                                          on: {
                                            deleteDefinition:
                                              _vm.openDeleteDialog,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "5px",
                                        "margin-left": "5px",
                                      },
                                      style:
                                        _vm.esModelLists.length == 0
                                          ? "height: 150px"
                                          : "",
                                      attrs: { flat: "" },
                                    },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          "align-items":
                                                            "center",
                                                          width: "100%",
                                                          height: "100%",
                                                        },
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openStorageDialog(
                                                              "es"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-plus"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1526094590
                                          ),
                                        },
                                        [_c("span", [_vm._v("add Model")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c(
                        VCardText,
                        {
                          staticStyle: {
                            width: "25%",
                            position: "fixed",
                            left: "40%",
                            top: "25%",
                          },
                        },
                        [
                          _vm.storageCondition
                            ? _c(
                                VCard,
                                [
                                  _c(
                                    VCardTitle,
                                    { staticClass: "headline" },
                                    [_vm._v("Save Project")]
                                  ),
                                  _c(
                                    VCardText,
                                    [
                                      _c("br"),
                                      _c(VTextField, {
                                        staticStyle: { "font-weight": "900" },
                                        attrs: {
                                          label: "* Project ID(Unique ID)",
                                          "error-messages":
                                            _vm.storageCondition.error &&
                                            _vm.storageCondition.error[
                                              "projectId"
                                            ],
                                        },
                                        model: {
                                          value: _vm.storageCondition.projectId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.storageCondition,
                                              "projectId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "storageCondition.projectId",
                                        },
                                      }),
                                      _c(VTextField, {
                                        attrs: { label: "Project Name" },
                                        model: {
                                          value:
                                            _vm.storageCondition.projectName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.storageCondition,
                                              "projectName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "storageCondition.projectName",
                                        },
                                      }),
                                      _c(VTextarea, {
                                        attrs: {
                                          outline: "",
                                          name: "input-7-4",
                                          label: "Comment",
                                          counter: 255,
                                          rows: "4",
                                        },
                                        model: {
                                          value: _vm.storageCondition.comment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.storageCondition,
                                              "comment",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "storageCondition.comment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCardActions,
                                    {
                                      staticStyle: {
                                        "justify-content": "right",
                                      },
                                    },
                                    [
                                      _vm.storageCondition.loading
                                        ? _c(VProgressCircular, {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          })
                                        : _c(
                                            VBtn,
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveProject()
                                                },
                                              },
                                            },
                                            [_vm._v("Save")]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c("ModelStorageDialog", {
                attrs: {
                  showDialog: _vm.showStorageDialog,
                  condition: _vm.storageCondition,
                },
                on: {
                  save: _vm.saveStorageDialog,
                  close: function ($event) {
                    return _vm.closeStorageDialog()
                  },
                },
              }),
              _vm.deleteCondition
                ? _c(
                    VDialog,
                    {
                      attrs: { persistent: "", "max-width": "470" },
                      model: {
                        value: _vm.showDeleteDialog,
                        callback: function ($$v) {
                          _vm.showDeleteDialog = $$v
                        },
                        expression: "showDeleteDialog",
                      },
                    },
                    [
                      _c(
                        VCard,
                        [
                          _c(VCardTitle, { staticClass: "headline" }, [
                            _vm._v(_vm._s(_vm.$t("word.deleteNotification"))),
                          ]),
                          _c(VImg, {
                            attrs: { src: _vm.deleteCondition.image },
                          }),
                          _c(VCardText, [
                            _vm._v(
                              "AuthorEmail: " +
                                _vm._s(
                                  _vm.deleteCondition.authorEmail.split("@")[0]
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _c(VCardText, [
                            _vm._v(
                              "ProjectName: " +
                                _vm._s(_vm.deleteCondition.projectName)
                            ),
                          ]),
                          _c(VCardText, [
                            _vm._v(
                              "date: " +
                                _vm._s(
                                  _vm.convertTimeStampToDate(
                                    _vm.deleteCondition.createdTimeStamp
                                  )
                                )
                            ),
                          ]),
                          _c(VCardText, [
                            _vm._v(
                              "LastModifiedDate: " +
                                _vm._s(
                                  _vm.convertTimeStampToDate(
                                    _vm.deleteCondition.lastModifiedTimeStamp
                                  )
                                )
                            ),
                          ]),
                          _c(VCardText, [
                            _vm._v(
                              '## 알림 ## "공유"된 파일의 경우 공동작업자가 사본을 생성할 수 있습니다.'
                            ),
                          ]),
                          _c(
                            VCardActions,
                            [
                              _c(VSpacer),
                              _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeDeleteDialog()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("word.close")))]
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { color: "red darken-1", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteDefinition()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("word.delete")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            VTabItem,
            [
              _vm.loading
                ? _c("AutoModelingDialog", {
                    ref: "autoModelingDialog",
                    attrs: {
                      mode: "project",
                      showChat: true,
                      projectId: _vm.projectId,
                      projectInfo: _vm.information,
                      isServer: _vm.isServer,
                      genType: _vm.generatorType,
                    },
                    on: {
                      closeDialog: function ($event) {
                        return _vm.close()
                      },
                      forceUpdateKey: _vm.forceUpdateKey,
                      saveProject: function ($event) {
                        return _vm.openStorageDialog("project")
                      },
                      backupProject: function ($event) {
                        return _vm.backupProject()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }